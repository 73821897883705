import React from 'react'
import { graphql } from 'gatsby'
import { TransitionState, TransitionLink } from "gatsby-plugin-transition-link"
import posed from "react-pose"
import { motion } from "framer-motion"

// import SEO from '../components/Seo'
import Layout from '../components/Layout'
import NavigationPartnershipsLogos from "../components/NavigationPartnershipsLogos"
import TriggerButton from '../components/TriggerButton'
import PartnershipsPagination from '../components/PartnershipsPagination'

const PageFade = posed.div({
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
})
const elementFade = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1
  }
}
const sequenceFade = {
  hidden: {
    opacity: 0,
    y: "10px"
  },
  visible: i => ({
    opacity: 1,
    y: 0,
    transition: {
      duration: .6,
      delay: i * 0.3,
      ease: "easeInOut"
    },
  })
}

const PartnershipsItemPage = (props) => {

  const { uid } = props.data.prismicPartnershipItem
  const partnershipsContent = props.data.allPrismicPartnerships.nodes[0].data
  const partnershipItems = props.data.allPrismicPartnerships.nodes[0].data.partnership_items
  const {title, intro, copy, link_url, link_copy, logo_full, image} = props.data.prismicPartnershipItem.data
  // console.log('partnershipItems ', partnershipItems)


  return (
    <Layout props={props}>
      <TransitionState>
        {({ transitionStatus }) => (
          <PageFade
            pose={
              ["entering", "entered"].includes(transitionStatus)
                ? "visible"
                : "hidden"
            }
          >
            {/* <SEO
              locale={props.pageContext.lang}
              title={metadata.metatitle}
              description={metadata.description}
              keywords={metadata.keywords}
              image={metadata.metadata_image.localFile.childImageSharp.fixed.src}
              pathname={props.location.pathname}
              origin={props.location.origin}
            /> */}

            <div className="partnerships-item-page wrapper is-fluid">
              <div className="columns is-desktop">
                <div className="column is-8 is-8-desktop is-offset-2 is-offset-2-desktop">
                  <div className="partnerships-item-page__content content has-text-centered">
                    <h2 className="is-size-2 is-size-1-fullhd has-text-white">
                      {partnershipsContent.copy.text}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="columns is-tablet">
                <div className="column is-10-desktop is-offset-1-desktop">
                  <NavigationPartnershipsLogos data={partnershipItems} uid={uid} />
                </div>
              </div>

              <motion.div
                initial="hidden"
                animate="visible"
                variants={elementFade}>
                <div className="columns is-tablet">
                  <div className="column is-10-desktop is-offset-1-desktop">
                    <div className="partnerships-item-modal content">
                      <TriggerButton 
                        icon="close" 
                        color="white" 
                        url="/partnerships"/>
                      </div>
                      <div className="columns is-tablet is-gapless">
                        <div className="partnerships-item__left column is-8 is-7-widescreen">
                          <div className="partnerships-item__content">
                            <img className="partnerships-item__full-logo"src={logo_full.localFile.url} />
                            <h1 className="is-size-3 has-text-white">{title.text}</h1>
                            <h3 className="is-size-4 has-text-white">{intro.text}</h3>
                            <p className="has-text-white">{copy.text}</p>

                            <a className="button is-rounded is-size-5-tablet is-size-4-widescreen" 
                              href={link_url.url} 
                              target="_blank">
                              <span className="button__text">{link_copy.text}</span>
                              <span className="icon">
                                <i></i>
                              </span>
                            </a>

                          </div>
                        </div>
                        <div className="partnerships-item__right column is-4 is-5-widescreen">
                          <div className="partnerships-item__bg" style={{ backgroundImage: `url(${image.localFile.url})`}}></div>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12">
                    <PartnershipsPagination data={partnershipItems} uid={uid} />
                  </div>
                </div>
              </motion.div>
            </div>

          </PageFade>
        )}
      </TransitionState>
    </Layout>
  )

}

export const partnershipsData = graphql`
  query PartnershipsItemQuery($uid: String!, $lang: String!) {
    allPrismicPartnerships(filter: {lang: {eq: $lang}}) {
      nodes {
        data {
          copy {
            text
          }
          partnership_items {
            partnership {
              id
              uid
              document {
                data {
                  logo {
                    localFile {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicPartnershipItem(uid: {eq: $uid}) {
      id
      uid
      data {
        title {
          text
        }
        intro {
          text
        }
        copy {
          text
        }
        link_url {
          url
        }
        link_copy {
          text
        }
        logo_full {
          localFile {
            url
          }
        }
        image {
          localFile {
            url
          }
        }
      }
    }
    allPrismicPartnershipItem(filter: {uid: {eq: $uid}, lang: {eq: $lang}}) {
      edges {
        next {
          uid
        }
        node {
          uid
        }
        previous {
          uid
        }
      }
    }
  }
`

export default PartnershipsItemPage;