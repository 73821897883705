import React from 'react'
import TransitionLink from "gatsby-plugin-transition-link"

const PartnershipsPagination = (props) => {

  const uid = props.uid
  const partnershipItems = props.data

  return (
    <div className="pagination__nav">
      {partnershipItems.map(({partnership}) => {
        return (
          <TransitionLink
            className={`pagination__item ${uid === partnership.uid ? 'active' : ''}`}
            to={`/${partnership.uid}`}
            exit={{ length: 0.5 }}
            key={partnership.uid}>
          </TransitionLink>
        )
      })}
    </div>
  )
}

export default PartnershipsPagination
